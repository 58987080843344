@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.footer {
    background-color: $c9c-main-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ;

    &__info {
        margin: 1rem; 
    } &__title {
        font-family: $c9c-main-font;
        font-size: 1rem
    } &__sub-title {
        font-family: $c9c-secondary-fonts;  
        font-size: .75rem;
        padding: .5rem;
    }

    &__socials {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
            @include desktop {
                justify-content: flex-start;
            }
    } &__Linkedin,
      &__Instagram,
      &__Facebook,
      &__GitHub,
      &__Shopify {
            @include tablet {
                justify-content: start;
                    @include desktop {
                        padding: 0 2rem;
                    }
            }
    } &__social-icons {
        width: 25px;
    } &__social-titles {
        font-family: $c9c-main-font;
        font-size:.75rem;
        margin: 0 1rem;
        @include hover;
    }

    &__policies {
        display: flex;
        align-items: center;
        justify-content: space-between;
    } &__copyright,
      &__policy {
        padding: 1rem;
        font-family: $c9c-main-font;
    }
}