@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.ecom {
  background-color: var(--background-color);
  color: var(--text-color);

    &__title {
      @include heading-text;
      padding: 2rem;
      margin: 0;
      ;
    } 
    
    &__top-section {
      @include flex-column-center;
      padding: 2rem;
      @include tablet {
        flex-direction: row;
        justify-content: space-around;
        padding: 4rem 2rem;
      }
    } 
    
    &__logo {
      width: 20%;
      margin: 0 2rem;
    } 
    
    &__sub-title {
      @include body-text;
      text-align: center;
    } 
    
    &__graphic {
      width: 100%;
          @include tablet {
              display: none;
          }
  }
}

// Animations
@keyframes slide {
  100% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.ecom__info {
  display: none;
  overflow: hidden;
  white-space: nowrap;
  background-color: var(--background-color);
  @include tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.ecom__info-section {
  display: inline-block;
  border-bottom: 1px solid var(--text-color);
  padding: 1rem;
  white-space: normal;
  @include tablet {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: slide 20s linear infinite;
    border-left: 1px solid var(--text-color);
    border-bottom: none;
  }
}

.ecom__info-title {
  @include body-text;
}

.ecom__info-desc {
  @include body-text; 
  margin: .5rem;
  @include tablet {
    width: 30rem;
  }
}