@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

body {
    margin: 1rem;
    border: 2px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
    @include box-shadow;
    transition: background-color 0.3s ease, color 0.3s ease;

    &.light-theme {
        @include light-theme;
    }

    &.dark-theme {
        @include dark-theme;
    }
}

a {
    text-decoration: none;
    color: var(--link-color);
    cursor: pointer;
}

ul,
li {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}