@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.privacy-policy {
    padding: 2rem;
    margin: 0 auto;
    background-color: var(--background-color);
      @include desktop {
        max-width: 60%;
      }
  
    &__title {
        @include heading-text;
        text-decoration: underline;
    } &__date {
        font-style: italic;
    } &__intro {
        @include body-text;
    } &__section-title {
        @include heading-text;
        text-decoration: underline;
    } &__text {
        padding: 1rem;
        @include body-text;
    } &__list {
        list-style: disc;
        @include body-text;
    } &__list-item {
      @include body-text;
        padding: 1rem;
    } &__link {
      color: $c9c-main-color;
      text-decoration: underline;
    } &__contact {
      padding: 1rem;
    }
  }