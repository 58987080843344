@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';


.notfound {
        @include flex-column-center;
        background-color: var(--background-color);
        color: var(--text-color);   
        padding: 2rem;
            @include desktop {
                padding: 8rem;
            }
        
    &__title {
        @include heading-text;
    } &__desc {
        @include body-text;
    } &__link {
        @include buttons;
    }
}