@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.hero {
    @include flex-column-center;
    color: var(--text-color);
    text-align: center;
    background: radial-gradient(circle, #4489c8 10%, var(--background-color) 80%, var(--background-color) 100%);
    padding: 2rem;
    margin: 0;

    &__logo {
        width: 70vw;
            @include desktop {
                width: 40vw;
            }
    } 
    
    &__title {
        @include heading-text;
    } 
    
    &__info {
        @include body-text;
        @include tablet {
            max-width: 60%;
        }
    } 
    
    &__cta-button {
        @include buttons;
    }
}