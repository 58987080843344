@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.webdev {
    background-color: var(--background-color);
    color: var(--text-color);

    &__title {
        @include heading-text;
        padding: 2rem;
        margin: 0;
    }

    &__top-section {
        @include flex-column-center;
        padding: 2rem;
        @include tablet {
            flex-direction: row;
            justify-content: space-around;
            padding: 4rem 2rem;
        }
    } 
    
    &__logo {
        width: 20%;
        margin: 0 2rem;
    } 
    
    &__sub-title {
        @include body-text;
    } 
    
    &__graphic {
        width: 100%;
            @include tablet {
                display: none;
            }
    }
}

@keyframes slide {
    100% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}

.webdev__info {
    display: none;
    overflow: hidden; 
    white-space: nowrap;
    @include tablet {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.webdev__info-section {
    display: inline-block; 
    border-bottom: 1px solid var(--text-color);
    padding: 1rem;
    white-space: normal;
    @include tablet {
        display: flex;
        flex-direction: row;
        align-items: center;
        animation: slide 20s linear infinite;
        border-left: 1px solid var(--text-color); 
        border-bottom: none;
    }
}

.webdev__info-title {
    @include heading-text;
}

.webdev__info-desc {
    @include body-text;
        @include tablet {
            width: 30rem;
        }
}