@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.home {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    
    &__about {
        @include flex-column-center;
    } 
    
    &__about-title {
       @include heading-text;
    } 
    
    &__about-description {
       @include body-text;
       text-align: center;
       padding: 1rem;
       color: var(--text-color);
       @include tablet {
           max-width: 60%;
       }
    } 
        
    &__about-profile {
        width: 50%;
        border-radius: 1000px;
        @include box-shadow;
        @include tablet {
            width: 40%;
            @include desktop {
                width: 20%;
            }
        }
    }

    &__portfolio {
        @include flex-column-center;
        padding: 1rem;
        text-align: center;
        background-color: var(--background-color);
        color: var(--text-color);
        @include desktop {
            padding: 6rem;
        }
    } 
    
    &__portfolio-title {
        @include heading-text;
    } 
    
    &__portfolio-item {
        @include flex-column-center;
        @include tablet {
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }
    } 
    
    &__portfolio-image {
        width: 90%;
        @include box-shadow;
        @include tablet {
            width: 50%;
        } 
    } 
    
    &__portfolio-description {
        @include body-text;
        padding: 2rem;
        color: var(--text-color);
        @include desktop {
            padding: 4rem;
        }
    }

    &__cta {
        @include flex-column-center;
        padding: 1rem;
        background-color: var(--background-color);
        color: var(--text-color);
    } 
    
    &__cta-title {
        @include heading-text;
        text-align: center;
        color: var(--text-color);
    } 
    
    &__cta-description {
       @include body-text;
       padding: 1rem;
       text-align: center;
       color: var(--text-color);
    } 
    
    &__cta-img {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        @include box-shadow;
        @include tablet {
            height: 350px;
            width: 350px;
        }
    } 
    
    &__cta-button {
        @include buttons;
        background-color: var(--background-color);
        color: var(--text-color);
        @include desktop {
            margin: 4rem;
        }
    }

    &__cta-link-1 {
        @include buttons;
        background-color: var(--background-color);
        color: var(--text-color);
        @include desktop {
            margin: 4rem;
        }
    } 
    
    &__cta-link-2 {
        @include buttons;
        background-color: var(--background-color);
        color: var(--text-color);
        @include desktop {
            margin: 4rem;
        }
    }

}