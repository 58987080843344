@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.services {
    @include flex-column-center;
    padding: 1rem;
    color: var(--text-color);
    @include moving-background;
        @include tablet {
            padding: 2rem;
    } 
    
    &__title {
        @include heading-text;
    } 
    
    &__sub-title {
        @include body-text;
        padding: 1rem;
        text-align: center;
        @include desktop {
            max-width: 50%;
        }
    } 
    
    &__cards-container {
        @include flex-column-center;
        @include tablet {
            justify-content: space-around;
            width: 700px;
                @include desktop {
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 80vw;
                    padding: 2rem;
                }
        }
    } 
    
    &__card-1,
    &__card-2,
    &__card-3,
    &__card-4 {
        background-color: var(--background-color);
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, .2);
        @include flex-column-center;
        margin: 2rem;
        padding: 1rem;
        @include tablet {
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            @include desktop {
                flex-direction: column;
                width: 40%;
            }
        }
    } 
    
    
    &__cards-upper-section {
        @include flex-column-center;
        align-items: center;
        @include tablet {
            max-width: 60%;
        }
    } 
    
    &__graphics {
      width: 75px;
    } 
    
    &__cards-title {
        @include heading-text;
            @include desktop {
                text-align: center;
            }
    } 
    
    &__cards-lower-section {
       display: flex;
       flex-direction: column;
       justify-content: space-between;
        @include tablet {
            width: 50%;
                @include desktop {
                    width: 90%;
                }
        }
    } 
    
    &__cards-description {
        @include body-text;
        text-align: center;
        padding: 1rem;
            @include desktop {
                padding: 0;
            }    
    } 

    &__button-section {
        @include buttons;
    }

}