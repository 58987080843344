@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.clients {
    @include moving-background;
    @include flex-column-center;
    background-color: var(--background-color);


    &__title {
        @include heading-text;
    }

    &__list {
        @include flex-column-center;
        justify-content: space-evenly;
        align-items: flex-start;
            @include tablet {
                flex-direction: row;
                flex-wrap: wrap;
            }
    }

    &__client-info {
        @include flex-column-center;
        @include box-shadow;
        background-color: var(--background-color );
        margin: 1rem;
        border: 1px solid var(--text-color);    
        border-radius: 10px;
            @include tablet {
                width: 40%;
                margin: 1rem;
                    @include desktop {
                        width: 30%;
                    }
            }
    }

    &__name {
       @include heading-text;
       text-align: center;
    }

    &__website {
      @include body-text;
    }

    &__job-task {
       @include body-text;
        width: 80%;
        text-align: center;
    }
}