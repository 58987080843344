@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';

.header {
    @include flex-column-center;
    background-color: var(--background-color, $c9c-off-white);
    color: var(--text-color, $c9c-off-black);
    font-family: $c9c-main-font;
    margin: 0;
    padding: 1rem;
    border-bottom: 2px solid var(--text-color);
    transition: opacity 0.3s ease;

    @include tablet {
        position: -webkit-sticky; 
        position: sticky;
        top: 0;
        z-index: 1000;
        flex-direction: row;
        justify-content: space-between;
        height: 50px;
    }

    &--scrolled {
        @include desktop {
            opacity: 0.8;
        }
    }

    &__title {
        text-transform: uppercase;
    }

    &__menu-list {
       @include flex-column-center;
        padding: 0;
            @include tablet {           
            flex-direction: row;
        }
    }

    &__menu-item {
        padding: .5rem;
        position: relative; 
        a, .header__dropdown-label {
            text-decoration: none;
            color: var(--text-color, $c9c-off-black);
            transition: color 0.3s ease;
        }

        &:hover .header__dropdown {
            display: block;
        }

        @include tablet {
            padding: 0 1rem;
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: 100%; 
        left: 0;
        background-color: var(--background-color);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        z-index: 1000;
        @include tablet {
            min-width: 150px;
        }

        &-item {
            margin-bottom: 0.5rem;

            a {
                text-decoration: none;
                color: var(--text-color);
                display: block;
                padding: 0.5rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: var(--text-color, $c9c-off-black);
                    color: var(--background-color, $c9c-off-white);
                }
            }
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__theme-icon {
        width: 24px;
        height: 24px;
        transition: all 0.3s ease;
    }
}
.header__arrow {
    display: inline-block;
    margin-left: .5rem;
    border: solid var(--text-color);
    border-width: 0 2px 2px 0;
    padding: .25rem;
    transform: rotate(45deg); 
}