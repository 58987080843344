@import '../../styles/partials/typeography.scss';
@import '../../styles/partials/mixins.scss';
@import '../../styles/partials/variables.scss';


.faq {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 1rem;
    text-align: center;

    &__title {
        @include heading-text;
    }

    &__main-container {
        @include flex-column-center;
        padding: 1rem;
        margin: 0 auto;
            @include tablet {
                width: 80%;
            }
    }

    &__list {
        @include tablet {
            width: 60%; 
        }
            @include desktop {
                width: 90%;
            }   
    } 

    &__spacer {
        padding: 1rem;
        border-bottom: 2px solid var(--text-color);
            @include tablet {
                padding: 2rem;
            }   
    }

    &__item {
        @include body-text;
    }

    &__question {
        @include heading-text;
    }
}