//break points
$tablet-breakpoint: 48rem;
$desktop-breakpoint: 80rem;

//main branding 
$c9c-main-color: #4489c8;
$c9c-off-black: #080808; //Name: Sandy Shoes
$c9c-off-white: #f5f5f5; //Name: Cultured

$c9c-main-font: 'comfortaa', sans-serif;
$c9c-secondary-fonts: ('Open-Sans', 'Raleway', 'Roboto', sans-serif);

//Complementary  colors
$complementary-color: #e0944c;
$analogous-color-1: #4464c8;
$analogous-color-2: #44c8c8;
$triadic-color-1: #c84489;
$triadic-color-2: #89c844;
